
body {
  background-color: #fcfcfc;
}

.form-control {
  width: 60%;
}

.form-control:focus {
  box-shadow: none;
}

.btn {
  margin-top: 10px;
}

.btn-submit {
  background-color: #2e7d32;
  color: white;
}

.vertical-timeline-element-date {
  color: #ffffff;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline-element-date {
    color: #494949;
  }
}